import store from "@/store";
import { getTaskEvent, fetchLMSUrl, showWelcomePopup, postWelcomeInformation } from "@/services/dashboard/dashboardService";

import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from "vuex-module-decorators";
import { updateTaskStatus } from "@/services/program/plannerService";
import { IQualityUserInfo } from "@/Model/model";


@Module({
  namespaced: true,
  name: "dashboard",
  store,
  dynamic: true
})

class DashboardModule extends VuexModule {
  public eventTaskData: any = {};
  public updatedCondenseTaskData: any = {};
  public getProlearningUrl : string = ''
  welcomeInformationData: any;

  get DashEvtTskList(){
    return this.eventTaskData;
  }

  get updateCondenseTask(){
    return this.updatedCondenseTaskData;
  }

  get getprofessionalUrl()
  {
    return this.getProlearningUrl
  }

  @Mutation
  public getTaskEventList(payload:any) {
    this.eventTaskData = payload
  }

  @Action({commit: "getTaskEventList"})
  public fetchCondenseTaskEvent(args:any):Promise<Object>{
    let resultNew = async function listOfPlanners(): Promise<Object> {
      let promise = getTaskEvent(args);
      let result = await promise; 
      return result as object;
    }
    return resultNew();
  }

  @Mutation
    public updatedCondenseTask(payload:any) {
      this.updatedCondenseTaskData = payload
    }
  
    @Action({commit: "updatedCondenseTask"})
    public async condenseTaskUpdated(payload: any):Promise<Object>{
      let promise = updateTaskStatus({id : payload.id, status : payload.status.id});
        let result = await promise; 
        return result as object;
    }

    @Mutation
    public getProfessionalLearningUrl(payload :any)
    {
      this.getProlearningUrl =payload
    }

    @Action({commit: "getProfessionalLearningUrl"})
    public async professionalLearningUrl(id: number):Promise<Object>{
      let promise = fetchLMSUrl(id) 
      let result = await promise; 
      return result as object;
    }

    @Mutation
      public mutateShowWelcomePopup(payload:any) {
        this.welcomeInformationData = payload
      }
      @Action({commit: "mutateShowWelcomePopup"})
      public async showWelcomePopup(payload:any):Promise<Object>{
        let promise = showWelcomePopup(payload);
        let result = await promise; 
        return result as object;
     } 

     @Mutation
     public mutatePostWelcomeInformation(payload:any) {
       this.welcomeInformationData = payload
     }
     @Action({commit: "mutatePostWelcomeInformation"})
     public async postWelcomeInformation(payload: IQualityUserInfo):Promise<Object>{
       let promise = postWelcomeInformation(payload);
       let result = await promise; 
       return result as object;
    }
 
}

export default getModule(DashboardModule);
