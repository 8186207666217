




































































































































































































































































































































































































































































































































































































import AdminDashboardComponent from '@/components/admindashboardcomponent/AdminDashboardComponent';
export default AdminDashboardComponent;
